import http from "../http-comm";

const getAll = () => {
  return http.get("/surveys/");
};

const create = (data) => {
  return http.post("/surveys/", data);
};

const recommand_major = data => {
  const { classes } = data;
  return http.get(`major/?classes=${classes}`);
};

export const SurveyDataService = {
  getAll,
  create,
  recommand_major,
};