export const majors = [
  { id: 0, name: "Agriculture" },
  { id: 1, name: "Architecture" },
  { id: 2, name: "Arts" },
  { id: 3, name: "Business" },
  { id: 4, name: "Communications" },
  { id: 5, name: "Computer Science" },
  { id: 6, name: "Construction" },
  { id: 7, name: "Education" },
  { id: 8, name: "Engineering" },
  { id: 9, name: "English" },
  { id: 10, name: "Ethnic Studies" },
  { id: 11, name: "Legal" },
  { id: 12, name: "Mathematics" },
  { id: 13, name: "Medical" },
  { id: 14, name: "Philosophy" },
  { id: 15, name: "Science" },
];
