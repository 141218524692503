// import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Layout from './components/Layout';
import Home from './components/Home';
import Survey from './components/Survey';
import SurveyList from './components/SurveyList';
import Recommend from './components/Recommend';
import NoPage from './components/NoPage';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="surveys/new" element={<Survey />} />
          <Route path="surveys" element={<SurveyList />} />
          <Route path="recommend" element={<Recommend />} />
          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
