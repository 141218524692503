import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
  return (
    <>
      <section className="hero-heading-center wf-section">
        <div className="container">
          <h1 className="centered-heading margin-bottom-32px">
            College Major Recommender
          </h1>
          <div className="hero-wrapper">
            <div className="hero-split">
              <p className="margin-bottom-24px">
                <h1 className="heading">Welcome to the New Science!!!</h1>
                Here you will be able to explore your potential majors based on your interests 
                and share it to others! Users who don’t know what their major is can go to 
                the recommended page to complete a form. Users who do know their major can 
                contribute to the website by going to the joining survey page after clicking 
                the dropdown from survey.
              </p>
              <Link className="button-primary w-button" to="/recommend">Start HERE</Link>
            </div>
            <div className="hero-split">
              <img
                src="images/Science-Vector.jpg"
                loading="lazy"
                srcSet="images/Science-Vector-p-500.jpg 500w, images/Science-Vector-p-800.jpg 800w, images/Science-Vector.jpg 806w"
                sizes="(max-width: 479px) 94vw, (max-width: 767px) 95vw, (max-width: 991px) 92vw, 43vw"
                alt="Science Symbol Design"
                className="shadow-two"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="footer-subscribe wf-section">
        <div className="container">
          <div className="footer-wrapper-three">
            <div className="footer-block-three">
              <a href="/#" className="footer-link-three">
                About us
              </a>
              <a href="/#" className="footer-link-three">
                Features
              </a>
              <a href="/#" className="footer-link-three">
                User Example
              </a>
              <a href="/#" className="footer-link-three">
                Resources
              </a>
            </div>
            <div className="footer-social-block-three">
              <a href="/#" className="footer-social-link-three w-inline-block">
                <img
                  src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a705912aaeb_facebook%20big%20filled.svg"
                  loading="lazy"
                  alt=""
                />
              </a>
              <a href="/#" className="footer-social-link-three w-inline-block">
                <img
                  src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124ab37a12aaf0_twitter%20big.svg"
                  loading="lazy"
                  alt=""
                />
              </a>
              <a href="/#" className="footer-social-link-three w-inline-block">
                <img
                  src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a61f512aaed_instagram%20big.svg"
                  loading="lazy"
                  alt=""
                />
              </a>
              <a href="/#" className="footer-social-link-three w-inline-block">
                <img
                  src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a717f12aaea_youtube%20small.svg"
                  loading="lazy"
                  alt=""
                />
              </a>
            </div>
          </div>
          <div className="footer-divider-two" />
          <div className="footer-bottom">
            <div className="footer-copyright">
              © 2022 Jeremiah Chun. All rights reserved
            </div>
            <div className="footer-legal-block">
              <a href="/#" className="footer-legal-link">
                Terms Of Use
              </a>
              <a href="/#" className="footer-legal-link">
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default Home;