import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { recommendMajor } from "../features/surveySlice";
import { classes } from "../data/classes";

const Recommend = () => {
  const initialState = {
    classes: "",
    major: null,
  };


  const dispatch = useDispatch();
  const [selected, setSelected] = useState(initialState);
  const [submitted, setSubmitted] = useState(false);

  const [checkedState, setCheckedState] = useState(
    new Array(classes.length).fill(false)
  );


  // events handlers...
  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);
    const selectedClass = updatedCheckedState.reduce(
      (str, currentState, index) => {
        if (currentState === true) {
          if (str !== "") {
            str += "+";
          }
          return str + classes[index].name;
        }
        return str;
      },
      ""
    );
    setSelected({ classes: selectedClass, major: null });
  };

  const resetSubmit = () => {
    setSubmitted(false);
    setSelected({ classes: "", major: null, });
    const updatedCheckedState = checkedState.map((item, index) => false);
    setCheckedState(updatedCheckedState);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    const { classes, } = selected;

    console.log(selected.classes);

    // @ts-ignore
    dispatch(recommendMajor({ classes }))
      .unwrap()
      .then((resData) => {
        setSelected({ classes: "", major: resData.major, });
      })
      .catch((e) => {
        console.log(e);
      });
  };


  // create 2D array of classes to show in multi columns
  const arr = classes.map(({ id, name }) => [name, id]);
  arr.sort();
  const arr_2D = [];
  while (arr.length) {
    arr_2D.push(arr.splice(0, 4));
  }


  return (
    <>
      <section className="hero-heading-center wf-section">
        <div className="container">
          {submitted ? (
            <div>
              <h1 className="heading">Popular Major Trends:</h1>
              <h3>{selected.major}</h3>
              <p />
              <button onClick={resetSubmit} className="button-primary w-button">
                Retry
              </button>
            </div>
          ) : (
            <div className="w-container">
              <h1>College Major Trends</h1>
              <p>To those still contemplating their futures, this is the page where you can discover your potential major!!! Select three classes and as a result who will get your suggested major! With an already established set of data, the website will find the closest data point from the set that matches with your choices right now and as a result pick a major!</p>
              <h1 className="heading">High School Classes</h1>
              <div>
                <table width={900}>
                  <tbody>
                    {arr_2D.slice(0, arr_2D.length).map((item, index) => {
                      return (
                        <tr key={`table-tr-${index}`}>
                          {item.map(([name, id]) => {
                            return (
                              <td key={`table-td-${id}`}>
                                <label>
                                  <input
                                    type="checkbox"
                                    id={`custom-checkbox-${id}`}
                                    name={name}
                                    value={name}
                                    checked={checkedState[id]}
                                    onChange={() => handleOnChange(id)}
                                  />{" "}
                                  {name}
                                </label>
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="container">
                <div className="classes-list-item">
                  <h4>Selected Classes:</h4>
                  <p>{selected.classes}</p>
                </div>
              </div>
              <p />
              <button onClick={handleSubmit} className="button-primary w-button">
                Submit
              </button>
            </div>
          )}
        </div>
      </section>

      <section className="footer-subscribe wf-section">
        <div className="container">
          <div className="footer-wrapper-three">
            <div className="footer-block-three">
              <a href="/#" className="footer-link-three">
                About us
              </a>
              <a href="/#" className="footer-link-three">
                Features
              </a>
              <a href="/#" className="footer-link-three">
                User Example
              </a>
              <a href="/#" className="footer-link-three">
                Resources
              </a>
            </div>
            <div className="footer-social-block-three">
              <a href="/#" className="footer-social-link-three w-inline-block">
                <img
                  src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a705912aaeb_facebook%20big%20filled.svg"
                  loading="lazy"
                  alt=""
                />
              </a>
              <a href="/#" className="footer-social-link-three w-inline-block">
                <img
                  src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124ab37a12aaf0_twitter%20big.svg"
                  loading="lazy"
                  alt=""
                />
              </a>
              <a href="/#" className="footer-social-link-three w-inline-block">
                <img
                  src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a61f512aaed_instagram%20big.svg"
                  loading="lazy"
                  alt=""
                />
              </a>
              <a href="/#" className="footer-social-link-three w-inline-block">
                <img
                  src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a717f12aaea_youtube%20small.svg"
                  loading="lazy"
                  alt=""
                />
              </a>
            </div>
          </div>
          <div className="footer-divider-two" />
          <div className="footer-bottom">
            <div className="footer-copyright">
              © 2022 Jeremiah Chun. All rights reserved
            </div>
            <div className="footer-legal-block">
              <a href="/#" className="footer-legal-link">
                Terms Of Use
              </a>
              <a href="/#" className="footer-legal-link">
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default Recommend;