export const classes = [
  { id: 0, name: "Biology" },
  { id: 1, name: "Business" },
  { id: 2, name: "Chemistry" },
  { id: 3, name: "Computer/IT" },
  { id: 4, name: "Economics" },
  { id: 5, name: "English" },
  { id: 6, name: "Foreign Language" },
  { id: 7, name: "General Education" },
  { id: 8, name: "History" },
  { id: 9, name: "Math" },
  { id: 10, name: "Other Science" },
  { id: 11, name: "Performing Arts" },
  { id: 12, name: "Physics" },
  { id: 13, name: "Sports" },
  { id: 14, name: "Visual Arts" },
];
