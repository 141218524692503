import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { SurveyDataService } from "../services/SurveyDataService";

export const retrieveSurveys = createAsyncThunk(
  "surveys/retrieve",
  async () => {
    console.log("surveys/retrieve");
    const res = await SurveyDataService.getAll();
    return res.data;
  }
);

export const createSurvey = createAsyncThunk(
  "surveys/create",
  // @ts-ignore
  async ({ name, class1, class2, class3, major }) => {
    const res = await SurveyDataService.create({ name, class1, class2, class3, major });
    return res.data;
  }
);

export const recommendMajor = createAsyncThunk(
  "surveys/major",
  // @ts-ignore
  async ({ classes }) => {
    const res = await SurveyDataService.recommand_major({ classes });
    return res.data;
  }
);

const initialState = {
  items: [],
};

const surveySlice = createSlice({
  name: "survey",
  initialState,
  reducers: {},
  extraReducers: {
    // @ts-ignore
    [retrieveSurveys.fulfilled]: (state, action) => {
      state.items = action.payload;
    },
    // @ts-ignore
    [createSurvey.fulfilled]: (state, action) => {
      state.items.push(action.payload);
    },
  },
});

const { reducer } = surveySlice;
export const surveyReducer = reducer;