import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { classes } from "../data/classes";
import { majors } from "../data/majors";
import { createSurvey } from "../features/surveySlice";

const Survey = () => {
  const initialSurvey = {
    name: "",
    classes: "",
    major: "",
  };

  const [checkedState, setCheckedState] = useState(
    new Array(classes.length).fill(false)
  );

  const [radioState, setRadioState] = useState(
    new Array(majors.length).fill(false)
  );

  const dispatch = useDispatch();
  const [selected, setSelected] = useState(initialSurvey);
  const [submited, setSubmitted] = useState(false);

  // events handler...
  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    setCheckedState(updatedCheckedState);

    const selectedClass = updatedCheckedState.reduce(
      (str, currentState, index) => {
        if (currentState === true) {
          if (str !== "") {
            str += "+";
          }
          return str + classes[index].name;
        }
        return str;
      },
      ""
    );

    setSelected({
      name: selected.name,
      classes: selectedClass,
      major: selected.major,
    });
  };

  const handleOnChangeMajor = (position) => {
    const updatedRadioState = radioState.map((item, index) =>
      index === position ? true : false
    );
    setRadioState(updatedRadioState);

    const selectedMajor = updatedRadioState.reduce(
      (str, currentState, index) => {
        if (currentState === true) {
          return (str = majors[index].name);
        }
        return str;
      },
      ""
    );

    setSelected({
      name: selected.name,
      classes: selected.classes,
      major: selectedMajor,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    const { name, classes, major } = selected;

    console.log(selected.name);
    console.log(selected.classes);
    console.log(selected.major);

    const arr_class = classes.split("+");
    const class1 = arr_class[0];
    const class2 = arr_class[1];
    const class3 = arr_class[2];

    // @ts-ignore
    dispatch(createSurvey({ name, class1, class2, class3, major }))
      .unwrap()
      .then((resData) => {
        console.log("Survey Created");
      })
      .catch((e) => {
        console.log(e);
      });

    // navigate("/surveys");
  };

  const resetSubmit = () => {
    setSubmitted(false);
    setSelected(initialSurvey);
    setCheckedState(new Array(classes.length).fill(false));
    setRadioState(new Array(majors.length).fill(false));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setSelected((state) => ({ ...state, [name]: value }));
  };


  // create 2D array of classes for display
  const arr = classes.map(({ id, name }) => [name, id]);
  arr.sort();
  const arr_2D = [];
  while (arr.length) {
    arr_2D.push(arr.splice(0, 4));
  }

  // create 2D array of majors for display
  const major_arr = majors.map(({ id, name }) => [name, id]);
  major_arr.sort();
  const major_2D = [];
  while (major_arr.length) {
    major_2D.push(major_arr.splice(0, 4));
  }

  return (
    <>
      <section className="hero-heading-center wf-section">
        <div className="w-container">
          {submited ? (
            <div>
              <h4>College Major Survey Submitted!</h4>
              <h5>{selected.classes + " - " + selected.major}</h5>
              <button onClick={resetSubmit} className="button-primary w-button">
                Retry
              </button>
            </div>
          ) : (
            <div className="container">
              <h1>College Major Survey</h1>
              <p>If you are trying to contribute to the website, you are in the right place!! 
                To complete this form, you select your three favorite class categories and 
                your major that resulted from these classes. By completing this survey, 
                you are contributing to making the recommended page more accurate!! 
                Your completed forms simply add another data point to an initial set of data 
                to help establish more trends between specific types of classes and majors! 
                Data points can be seen through the survey list page.</p>
              <h1 className="heading">High School Classes</h1>
              {/* <h3>Select Classes</h3> */}
              <div>
                <table width={900}>
                  <tbody>
                    {arr_2D.slice(0, arr_2D.length).map((item, index) => {
                      return (
                        <tr key={`table-tr-${index}`}>
                          {item.map(([name, id]) => {
                            return (
                              <td key={`table-td-${id}`}>
                                <label>
                                  <input
                                    type="checkbox"
                                    id={`custom-checkbox-${id}`}
                                    name={name}
                                    value={name}
                                    checked={checkedState[id]}
                                    onChange={() => handleOnChange(id)}
                                  />{" "}
                                  {name}
                                </label>
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="container">
                <div className="classes-list-item">
                  <h4>Selected Classes:</h4>
                  <p>{selected.classes}</p>
                </div>
              </div>

              <h3>Majors</h3>
              <div>
                <table width={900}>
                  <tbody>
                    {major_2D.slice(0, major_2D.length).map((item, index) => {
                      return (
                        <tr key={`table-tr-${index}`}>
                          {item.map(([name, id]) => {
                            return (
                              <td key={`table-td-${id}`}>
                                <label>
                                  <input
                                    type="radio"
                                    value={name}
                                    checked={radioState[id]}
                                    onChange={() => handleOnChangeMajor(id)}
                                  />{" "}
                                  {name}
                                </label>
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="container">
                <div className="classes-list-item">
                  <h5>Selected Major:</h5>
                  <p>{selected.major}</p>
                </div>
              </div>

              <div>
                <label htmlFor="name-2">Name</label>
                <input
                  type="text"
                  className="w-input"
                  maxLength={256}
                  name="name"
                  data-name="name"
                  placeholder="Name"
                  id="name-2"
                  onChange={handleInputChange}
                />
              </div>

              <button onClick={handleSubmit} className="button-primary w-button">
                Submit
              </button>
            </div>
          )}
        </div>
      </section>

      <section className="footer-subscribe wf-section">
        <div className="container">
          <div className="footer-wrapper-three">
            <div className="footer-block-three">
              <a href="/#" className="footer-link-three">
                About us
              </a>
              <a href="/#" className="footer-link-three">
                Features
              </a>
              <a href="/#" className="footer-link-three">
                User Example
              </a>
              <a href="/#" className="footer-link-three">
                Resources
              </a>
            </div>
            <div className="footer-social-block-three">
              <a href="/#" className="footer-social-link-three w-inline-block">
                <img
                  src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a705912aaeb_facebook%20big%20filled.svg"
                  loading="lazy"
                  alt=""
                />
              </a>
              <a href="/#" className="footer-social-link-three w-inline-block">
                <img
                  src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124ab37a12aaf0_twitter%20big.svg"
                  loading="lazy"
                  alt=""
                />
              </a>
              <a href="/#" className="footer-social-link-three w-inline-block">
                <img
                  src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a61f512aaed_instagram%20big.svg"
                  loading="lazy"
                  alt=""
                />
              </a>
              <a href="/#" className="footer-social-link-three w-inline-block">
                <img
                  src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a717f12aaea_youtube%20small.svg"
                  loading="lazy"
                  alt=""
                />
              </a>
            </div>
          </div>
          <div className="footer-divider-two" />
          <div className="footer-bottom">
            <div className="footer-copyright">
              © 2022 Jeremiah Chun. All rights reserved
            </div>
            <div className="footer-legal-block">
              <a href="/#" className="footer-legal-link">
                Terms Of Use
              </a>
              <a href="/#" className="footer-legal-link">
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* <h1>Survey</h1>
      <div className="w-form">
        <form
          id="contact-form"
          name="contact-form"
          data-name="Contact Form"
          method="get"
        >
          <label htmlFor="name-2">Name</label>
          <input
            type="text"
            className="w-input"
            maxLength={256}
            name="name"
            data-name="name"
            placeholder="Name"
            id="name-2"
            onChange={handleInputChange}
          />
          <label htmlFor="class1">Class 1</label>
          <input
            type="text"
            className="w-input"
            maxLength={256}
            name="class1"
            data-name="class1"
            placeholder="Class 1"
            id="class1"
            onChange={handleInputChange}
          />
          <label htmlFor="class1">Class 2</label>
          <input
            type="text"
            className="w-input"
            maxLength={256}
            name="class2"
            data-name="class2"
            placeholder="Class 2"
            id="class2"
            onChange={handleInputChange}
          />
          <label htmlFor="class1">Class 3</label>
          <input
            type="text"
            className="w-input"
            maxLength={256}
            name="class3"
            data-name="class3"
            placeholder="Class 3"
            id="class3"
            onChange={handleInputChange}
          />
          <label htmlFor="major">major</label>
          <input
            type="text"
            className="w-input"
            maxLength={256}
            name="major"
            data-name="phonemajor"
            placeholder="College Major"
            id="major"
            onChange={handleInputChange}
          />
          <input
            type="submit"
            defaultValue="Submit"
            data-wait="Please wait..."
            className="w-button"
            onClick={handleSubmitClick}
          />
        </form>
      </div> */}
    </>
  )
}
export default Survey;