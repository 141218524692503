import React from "react";
import { Link, Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <>
      <div className="navbar-logo-left wf-section">
        <div
          data-animation="default"
          data-collapse="medium"
          data-duration={400}
          data-easing="ease"
          data-easing2="ease"
          role="banner"
          className="navbar-logo-left-container shadow-three w-nav"
        >
          <div className="container">
            <div className="navbar-wrapper">
              <Link
                className="navbar-brand w-nav-brand w--current"
                aria-current="page"
                to="/">
                <img
                  loading="lazy"
                  width={244}
                  src="images/Jeremiah-Chun-Logo-Wide.jpg"
                  alt=""
                />
              </Link>
              <nav role="navigation" className="nav-menu-wrapper w-nav-menu">
                <ul role="list" className="nav-menu-two w-list-unstyled" >
                  <li>
                    <Link className="nav-link" to="/">Home</Link>
                  </li>
                  <li>
                    <div
                      data-hover="false"
                      data-delay={0}
                      className="nav-dropdown-2 w-dropdown"
                    >
                      <div className="nav-dropdown-toggle-2 w-dropdown-toggle">
                        <div className="nav-dropdown-icon-2 w-icon-dropdown-toggle" />
                        <div>Survey</div>
                      </div>
                      <nav className="nav-dropdown-list-2 shadow-three mobile-shadow-hide w-dropdown-list">
                        <Link className="nav-dropdown-link-2 w-dropdown-link" to="/surveys/new">Survey</Link>
                        <Link className="nav-dropdown-link-2 w-dropdown-link" to="/surveys">SurveyList</Link>
                      </nav>
                    </div>
                  </li>
                  <li>
                    <Link className="nav-link" to="/recommend">Recommend</Link>
                  </li>
                  <li>
                    <a
                      href="https://jeremiahs-portfolio-d9c0c8.webflow.io/"
                      target="_blank"
                      rel="noreferrer"
                      className="nav-link"
                    >
                      About
                    </a>
                  </li>
                  <li>
                    <div className="nav-divider" />
                  </li>
                  <li>
                    <a href="/#" className="nav-link-accent">
                      Docs
                    </a>
                  </li>
                  <li className="mobile-margin-top-10">
                    <Link className="button-primary w-button" to="/surveys/new">Join Survey</Link>
                  </li>
                </ul>
              </nav>
              <div className="menu-button w-nav-button">
                <div className="w-icon-nav-menu" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Outlet />
    </>
  )
}
export default Layout;