import { AgGridReact } from "ag-grid-react";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { retrieveSurveys } from "../features/surveySlice";
import 'ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import 'ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS

const SurveyList = () => {
  const dispatch = useDispatch();
  // @ts-ignore
  const surveys = useSelector((state) => state.survey.items);

  const [columnDefs] = useState([
    { field: "id", width: 80, resizable: true },
    { field: "name", width: 150, resizable: true },
    { field: "class1", width: 150, resizable: true },
    { field: "class2", width: 150, resizable: true },
    { field: "class3", width: 150, resizable: true },
    { field: "major", },
  ]);

  useEffect(() => {
    console.log("Survey List: useEffect");
    // @ts-ignore
    dispatch(retrieveSurveys());
  }, [dispatch]);


  return (
    <>
      <section className="hero-heading-center wf-section">
        <div className="w-container">
          <div className="wf-section">
            <div className="w-container">
              <div id="contact-title" className="contact-title">
                <h1>Survey List</h1>
                <p>Here are all of our data points to create results for our recommended page survey. 
                  How this website operates is that it has been given an initial set of many data points 
                  consisting of linked classes and their resulting major from all kinds of people. 
                  As a result, the website utilizes the capabilities of machine learning to analyze and 
                  create patterns based on these inputted choices. The choices listed here will be 
                  the foundation of finding majors for future users on the recommended page.</p>
                <h1 className="heading">Survey Participants</h1>
              </div>
            </div>
          </div>
          <div className="ag-theme-alpine">
            <AgGridReact
              rowData={surveys}
              columnDefs={columnDefs}
              domLayout="autoHeight"
              pagination={true}
              paginationPageSize={10}
            ></AgGridReact>
          </div>

        </div>
      </section>

      <section className="footer-subscribe wf-section">
        <div className="container">
          <div className="footer-wrapper-three">
            <div className="footer-block-three">
              <a href="/#" className="footer-link-three">
                About us
              </a>
              <a href="/#" className="footer-link-three">
                Features
              </a>
              <a href="/#" className="footer-link-three">
                User Example
              </a>
              <a href="/#" className="footer-link-three">
                Resources
              </a>
            </div>
            <div className="footer-social-block-three">
              <a href="/#" className="footer-social-link-three w-inline-block">
                <img
                  src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a705912aaeb_facebook%20big%20filled.svg"
                  loading="lazy"
                  alt=""
                />
              </a>
              <a href="/#" className="footer-social-link-three w-inline-block">
                <img
                  src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124ab37a12aaf0_twitter%20big.svg"
                  loading="lazy"
                  alt=""
                />
              </a>
              <a href="/#" className="footer-social-link-three w-inline-block">
                <img
                  src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a61f512aaed_instagram%20big.svg"
                  loading="lazy"
                  alt=""
                />
              </a>
              <a href="/#" className="footer-social-link-three w-inline-block">
                <img
                  src="https://uploads-ssl.webflow.com/62434fa732124a0fb112aab4/62434fa732124a717f12aaea_youtube%20small.svg"
                  loading="lazy"
                  alt=""
                />
              </a>
            </div>
          </div>
          <div className="footer-divider-two" />
          <div className="footer-bottom">
            <div className="footer-copyright">
              © 2022 Jeremiah Chun. All rights reserved
            </div>
            <div className="footer-legal-block">
              <a href="/#" className="footer-legal-link">
                Terms Of Use
              </a>
              <a href="/#" className="footer-legal-link">
                Privacy Policy
              </a>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default SurveyList;